

















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import ParityFiltersService, { ParityFiltersServiceS } from '../../parity-filters.service';

@Component({
    components: { CustomMultiSelect },
})
export default class ParityLokFilter extends Vue {
    @Inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @Inject(UserServiceS) private userService!: UserService;

    get items(): Item[] {
        const { availableLors } = this.parityFiltersService;
        return availableLors.map(value => ({
            name: String(value),
            value,
        }));
    }

    get currentValue() {
        if (!this.paritySettingsService.storeState.settings.lor) {
            return [];
        }

        return this.paritySettingsService.storeState.settings.lor.map(lor => ({
            name: lor,
            value: lor,
        }));
    }
    set currentValue(value) {
        if (value) {
            this.paritySettingsService.lor = value.map(item => item.value);
        }
    }
}
